import {toast} from "react-toastify";

//Default options for the toast. We can create additional options for different types of toast messages if needed
const DEFAULT_TOAST_OPTIONS = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
}

export function handleToastMessage(message) {
    toast.info(message, DEFAULT_TOAST_OPTIONS);
}

export function handleToastError(errorMessage, options) {
    toast.error(errorMessage, options ? {...DEFAULT_TOAST_OPTIONS, ...options} : DEFAULT_TOAST_OPTIONS);
}

export function handleToastWarning(warningMessage) {
    toast.warning(warningMessage, DEFAULT_TOAST_OPTIONS);
}