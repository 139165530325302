import {CssBaseline, GlobalStyles} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import Login from "./views/login/Login";
import liftPortalTheme from "./themes/liftPortalTheme";
import MfaForm from "./views/mfa/MfaForm";
import SsoEnforced from "./views/login/SsoEnforced";
import LoginForm from "./views/login/LoginForm";
import SignUp from "./views/login/SignUp";

const theme = createTheme(liftPortalTheme);

const router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to={"/login"}/>
    },
    {
        path: "/login",
        element: <Login/>,
        children: [
            {index: true, element: <LoginForm/>},
            {path: "/login/sso-enforced", element: <SsoEnforced/>}
        ]
    },
    {
        path: "/mfa",
        element: <MfaForm/>
    },
    {
        path: "/signup/:id",
        element: <SignUp/>
    }
]);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles styles={{ul: {margin: 0, padding: 0, listStyle: 'none'}}}/>
            <CssBaseline/>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <RouterProvider router={router}/>
        </ThemeProvider>
    );
}

export default App;
