import React from "react";
import {ThemeProvider} from "@mui/material";
import Logo from "../../assets/images/elemica_blue_gradient_large.svg";
import Grid from "@mui/material/Grid";
import {Link, Outlet} from "react-router-dom";
import { noAsteriskTheme, CaptionLink, LoginBox, BackgroundedCard, BoxCentered } from '../../themes/PortalStyling';

const dockHeroImage = require('../../assets/images/hero-images/dock-hero.jpg');
    

export default function Login() {

    
    return (
        <BackgroundedCard  heroImage={dockHeroImage}>
            <LoginBox>
                <Grid container xs display="flex" justifyContent="center" alignItems="center" sx={{mt: 5, mb: 3}}>
                    <Link to="https://elemica.com/">
                        <img src={Logo} alt="Logo" width="80px" height="20px"/>
                    </Link>
                </Grid>
                <ThemeProvider theme={noAsteriskTheme}>
                    <Outlet/>
                </ThemeProvider>
                <BoxCentered sx={{ mt: 3, mb: 3 }}>
                    <CaptionLink
                        target="_blank"
                        rel="noreferrer"
                        href="https://elemica.com/legal/privacy/"
                    >
                        Privacy Policy
                    </CaptionLink>
                </BoxCentered>

            </LoginBox>
        </BackgroundedCard>
    );
}