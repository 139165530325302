import { Error, Visibility, VisibilityOff } from '@mui/icons-material';
import { Avatar, Box, Card, Grid, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import axios from "axios";
import React, { useState } from 'react';
import { Body2Typography, CaptionLink, CaptionTypography, ErrorMessage } from "../themes/PortalStyling";
import { getIdPortalHost, redirectToDefaultLandingPage } from "../utils/Common";

import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

export default function ActivateAccountForm({ id }) {

    const axiosInstance = axios.create();
    const [showPassword, setShowPassword] = useState(false);
    const [errorSubmitting, setErrorSubmitting] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const [formValues, setFormValues] = useState({
        "accessKey": id,
        "firstName": "",
        "lastName": "",
        "password": ""
    })

    const SignUpFormValidationSchema = Yup.object().shape({
        firstName: Yup.string().required("First Name is require"),
        lastName: Yup.string().required("Last Name is required"),
        password: Yup.string()
            .required("Password is required")
            .test('password', 'Password does not meet criteria', (value) => validatePassword(value))
    })

    const formMethods = useForm({
        values: formValues,
        resolver: yupResolver(SignUpFormValidationSchema)
    });

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const validatePassword = (password) => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        return password.length >= 10 && [hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar].filter(Boolean).length >= 3;
    };

    const handleSubmit = (data) => {
        setSubmitting(true)
        axiosInstance
            .post(
                window.location.protocol + "//" + getIdPortalHost() + "/signup",
                data,
                {
                    withCredentials: true,
                    headers: {
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                    }
                }
            )
            .then(response => {
                setErrorSubmitting('');
                redirectToDefaultLandingPage();
            }
            )
            .catch((errorResponse) => {
                setErrorSubmitting(errorResponse.response?.data?.errorMessage);
            })
            .finally(() => setSubmitting(false));
    };

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={e => {
                formMethods.clearErrors()
                formMethods.handleSubmit(handleSubmit)(e)
            }}
            >
                <Stack
                    alignItems="center"
                    spacing={2}
                >
                    <Controller
                        name="firstName"
                        render={({ field, fieldState }) =>
                            <TextField variant="outlined"
                                size="small"
                                label="First Name"
                                fullWidth
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message}
                                {...field} />
                        }
                    />

                    <Controller
                        name="lastName"
                        render={({ field, fieldState }) =>
                            <TextField variant="outlined"
                                size="small"
                                label="Last Name"
                                fullWidth
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message}
                                {...field} />
                        }
                    />

                    <Controller
                        name="password"
                        render={({ field, fieldState }) =>
                            <TextField variant="outlined"
                                size="small"
                                label="Choose Password"
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                margin="normal"
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                {...field} />
                        }
                    />
                </Stack>
                <Box display="block" sx={{
                    backgroundColor: '#B0E0F4',
                    color: 'black',
                    padding: '8px',
                    borderRadius: '4px',
                    mt: 2,
                    mb: 2,
                    padding: 2
                }}>
                    <Grid container alignItems="flex-start">
                        <Grid item xs={1}>
                            <Avatar sx={{
                                bgcolor: '#007CBB',
                                color: 'white',
                                width: 24,
                                height: 24,
                                fontSize: '0.75rem'
                            }}>i</Avatar>
                        </Grid>
                        <Grid item xs={11} sx={{ pl: 1 }}>
                            <Body2Typography align="left">
                                Password must be new, contain at least 10 characters and 3 of the following
                                attributes:
                            </Body2Typography>
                            <Box>
                                <Body2Typography align="left">- An uppercase letter</Body2Typography>
                                <Body2Typography align="left">- A lowercase letter</Body2Typography>
                                <Body2Typography align="left">- A number</Body2Typography>
                                <Body2Typography align="left">- A special character</Body2Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                {errorSubmitting && <Card sx={{ mt: 3 }}>
                <ErrorMessage>
                    <Grid container direction="row" wrap="false" >
                        <SvgIcon sx={{ color: "#D11F2F", mr: 1 }}>
                            <Error />
                        </SvgIcon>
                        <Body2Typography>{errorSubmitting}</Body2Typography>
                    </Grid>
                </ErrorMessage>
            </Card>
            }
            <Box mt={2} mb={3}>
                <LoadingButton type="submit" variant="contained" color="primary"
                        fullWidth
                        loading={submitting}
                        loadingPosition="start"
                        startIcon={<></>}
                    >
                        <span>{submitting ? "Creating Account..." : "Create Account"}</span>
                    </LoadingButton>
                </Box>
            </form>
        </FormProvider>
    );
};
