import React from "react";
import {Typography} from "@mui/material";
import {getOauth2RedirectUrl} from "../../utils/Common";
import Grid from "@mui/material/Grid";
import { LoginButton } from '../../themes/PortalStyling';

export default function SsoEnforced() {

    function handleOauth2Signin() {
        window.location = getOauth2RedirectUrl("azure-client")
    }


    return <>

        <Grid container justifyContent="center" direction="column" alignItems="center" style={{color: '#808080'}}>
            <Typography variant="h5">Single Sign On</Typography>
            <Typography>Your organization requires single sign-on for
                authentication.</Typography>
        </Grid>

        <LoginButton
            type="button"
            fullWidth
            sx={{
                mt: 3,
                mb: 2
            }}
            variant="contained"
            onClick={handleOauth2Signin}
        >Continue
        </LoginButton>
    </>
}