import React, { useState }from "react";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import Logo from "../../assets/images/elemica_blue_gradient_large.svg";
import {getIdPortalHost} from "../../utils/Common";
import axios from "axios";
import {useForm} from "react-hook-form";
import {handleToastError} from "../../utils/ToastHelper";
import {CardContent, Typography, Box, TextField, } from "@mui/material";
import {useLocation} from "react-router-dom";
import { Body2Typography, Body2Link, CaptionLink, HorizontalLine, GreyTextGrid, BackgroundedCard, BoxCentered } from '../../themes/PortalStyling';

const axiosInstance = axios.create();
const idPortalLoginErrorCookieName = "ID_PORTAL_LOGIN_ERROR";
const dockHeroImage = require('../../assets/images/hero-images/dock-hero.jpg');

export default function MfaForm() {
    const [signInLoading, setSignInLoading] = React.useState(false);
    const {state} = useLocation();
    const [error, setError] = useState('');
    const qrCodeUrl = "data:image/png;base64, " + state.qrCode;

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const onSubmit = data => {
        setSignInLoading(true);
        const mfaCodeArray = [];
        for (let i = 0; i < 6; i++) {
            const input = document.getElementById(`mfaCode${i}`);
            if (input) {
                mfaCodeArray.push(input.value);
            }
        }
        const mfaCode = mfaCodeArray.join('');
        if (mfaCode.length !== 6) {
            setError('MFA code must be exactly 6 digits.');
            setSignInLoading(false);
        } else {
            setError('');
            axiosInstance
                .post(
                    window.location.protocol + "//" + getIdPortalHost() + "/mfa",
                    `mfaCode=${mfaCode}`,
                    {
                        withCredentials: false,
                        headers: {
                            "Accept": "application/json",
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    }
                )
                .then(response => {
                    const redirectUrl = response.data?.redirectUrl;
                    const errorText = response.data?.error;
                    if (errorText) {
                        handleToastError(errorText)
                        // following discussion with Product, leaving the user here to try again
                    } else if (redirectUrl) {
                        window.location.href = redirectUrl;
                    }
                })
                .catch((errorResponse) => {
                    const errorMessage = errorResponse.response?.data?.errorMessage;
                    handleToastError(errorMessage, {autoClose: false, closeOnClick: false})
                })
                .finally(() => {
                    setSignInLoading(false);
                });
        }
    }

    return (
        <BackgroundedCard  heroImage={dockHeroImage}>
            <CardContent sx={{ backgroundColor: 'white',
                            maxWidth: '420px',
                            margin: 'auto', padding: '8'}}>
                <BoxCentered sx={{ display: 'flex', flexDirection: 'column', px: 5 }}>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate >
                        <GreyTextGrid container display="flex" justifyContent="center" alignItems="center" textAlign="center"
                            sx={{mb: 3}}>
                            <Link to="https://elemica.com/" sx={{ mt: 3, mb: 3}}>
                                    <img src={Logo} alt="Logo" width="80px" height="20px"/>
                            </Link>
                            {state.requiresMfaSetup &&
                            <>
                                <Typography variant="h4" sx={{ mb: 1 }}>Set up Multifactor Authentication</Typography>
                                <Body2Typography>Scan the following QR code using an authenticator app of your choosing:</Body2Typography>
                                <img src={qrCodeUrl}/>
                                {state?.privateKey && (
                                    <>
                                        <Body2Typography>If the QR code doesn't work, manually enter the following key into your authenticator app:</Body2Typography>
                                        <Body2Typography sx={{ color: '#1e98d4' }}>{state?.privateKey}</Body2Typography>
                                    </>
                                )}
                            </>
                            }
                            <BoxCentered sx={{ display: 'flex', flexDirection: 'column' }}>
                                <HorizontalLine />
                                <Body2Typography sx={{ mb: 1 }}>
                                    Enter the MFA code generated by your authenticator app:
                                </Body2Typography>
                                <BoxCentered sx={{ display: 'flex', gap: 1 }}>
                                    {[...Array(6)].map((_, i) => (
                                        <TextField
                                            key={i}
                                            inputmode="numeric"
                                            id={`mfaCode${i}`}
                                            required
                                            {...register(`mfaCode${i}`)}
                                            inputProps={{ maxLength: 1 }}
                                            sx={{
                                                width: 45,
                                                '& .MuiInputBase-input': {
                                                    color: 'black',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    borderColor: 'grey',
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#1e98d4',
                                                    },
                                                },
                                            }}
                                            variant="outlined"
                                            onChange={(e) => {
                                                e.target.value = e.target.value.slice(-1);
                                                if (e.target.value) {
                                                    document.getElementById(`mfaCode${i+1}`)?.focus();
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Backspace' && e.target.value === '') {
                                                    document.getElementById(`mfaCode${i-1}`)?.focus();
                                                }
                                            }}
                                            onInput={(e) => {
                                                if (!/[0-9]/.test(e.key)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                const paste = e.clipboardData.getData('text');
                                                const digits = paste.replace(/\D/g, ''); // Remove non-digit characters
                                                digits.split('').forEach((char, index) => {
                                                    const input = document.getElementById(`mfaCode${index}`);
                                                    if (input) {
                                                        input.value = char;
                                                    }
                                                });
                                            }}
                                        />
                                    ))}
                                </BoxCentered>
                            </BoxCentered>
                            <LoadingButton
                                type="submit"
                                sx={{mt: 3, mb: 2}}
                                fullWidth
                                loading={signInLoading}
                                disabled={signInLoading}
                                loadingPosition="end"
                                variant="contained"
                            >
                                Verify Registration
                            </LoadingButton>
                            {error && <div style={{ color: 'red' }}>{error}</div>}
                            <Box sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
                                <Body2Typography variant="body1" sx={{ textAlign: 'center' }}>
                                    Having trouble? Contact&nbsp;
                                        <Body2Link
                                            sx={{ textAlign: 'center' }}
                                            target="_blank"
                                            rel="noreferrer"
                                            href="mailto:support@elemica.com"
                                        >
                                            support@elemica.com
                                        </Body2Link>
                                    </Body2Typography>
                            </Box>
                            <BoxCentered sx={{ width: '100%', mt: 3 }}>
                                <CaptionLink
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://elemica.com/legal/privacy/"
                                >
                                    Privacy Policy
                                </CaptionLink>
                            </BoxCentered>
                        </GreyTextGrid>
                    </form>
                </BoxCentered>
            </CardContent>
        </BackgroundedCard>
    );
}
