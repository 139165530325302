import {grey} from "@mui/material/colors";

const fonts = "'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"

const elemicanPalette = {
    background: {
        default: "#E6E6E6"
    },
    primary: {
        main: "#27AAE1",
        900: "#004D87",
        800: "#006CA7",
        700: "#007CBB",
        600: "#008ECE",
        500: "#009CDC",
        400: "#27AAE1",
        300: "#4DB8E5",
        200: "#7DCCED",
        100: "#B0E0F4",
        50: "#E0F3FB"
    },
    secondary: {
        main: "#E6E6E6",
        900: "#262626",
        800: "#484848",
        700: "#676767",
        600: "#7B7B7B",
        500: "#A5A5A5",
        400: "#C3C3C3",
        300: "#E6E6E6",
        200: "#F1F1F1",
        100: "#F6F6F6",
        50: "#FBFBFB",
        0: "#FFFFFF"    // placeholder for white, not sure where it should go.
    },
}

const liftPortalTheme = {
    components: {
        MuiLink: {
            defaultProps: {
                underline: 'none',
            },
        },
        MuiAppBar: {
            defaultProps: {
                elevation: 0,
                position: "static",
            },
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "white",
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    '& .MuiTableCell-head': {
                        backgroundColor: elemicanPalette.primary[200],
                        color: elemicanPalette.secondary[0],
                        textTransform: 'uppercase'
                    }
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    color: "#FFFFFF"
                },
                containedSecondary: {
                    color: "#616161"
                }
            }
        }
    },
    typography: {
        fontFamily: fonts
    },
    palette: elemicanPalette
};

export default liftPortalTheme;
