import React from 'react';
import { Box } from '@mui/material';
import { GreyTextGrid, H4Typography, Subtitle1Typography, LoginButton } from '../themes/PortalStyling';

const ConfigureSsoMessage = ({ setShowConfigureSSOMessage, contactEmail }) => {
    return (
        <>
            <GreyTextGrid container justifyContent="center" direction="column" alignItems="center">
                <H4Typography sx={{ mb: 0 }}>Single Sign On</H4Typography>
                <Subtitle1Typography sx={{ mt: 0, mb: 1.5, mx: 3, textAlign: 'center' }}>
                    <b>Your company is not yet configured for Single Sign On (SSO).</b> If you are interested in enabling Single Sign On for your company, please reach out to Elemica to {contactEmail ?
                        <a href={`mailto:${contactEmail}?subject=SSO%20Implementation%20Request`}>request SSO implementation</a> :
                        <>request SSO implementation</>}.
                </Subtitle1Typography>
                <Box sx={{ width: '80%' }}>
                    <LoginButton
                        loadingPosition="end"
                        sx={{ mt: 1, mb: 2 }}
                        fullWidth
                        variant="contained"
                        onClick={() => setShowConfigureSSOMessage(false)}
                    >
                        Go back to login
                    </LoginButton>
                </Box>
            </GreyTextGrid>
        </>
    );
};

export default ConfigureSsoMessage;
