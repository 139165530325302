import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Box, IconButton, InputAdornment, Typography, Grid, Link, Avatar, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import axios from "axios";
import {
    getIdPortalHost,
    getNetworkPortalHost,
    getOauth2RedirectUrl,
    redirectToDefaultLandingPage
} from "../../utils/Common";
import { handleToastError } from "../../utils/ToastHelper";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Error, InfoSharp, Visibility, VisibilityOff, PermIdentityRounded } from "@mui/icons-material";
import { LoginButton, InvertedColoursButton, Body2Typography, GreyTextGrid, Body2Label, Subtitle1Typography, H4Typography, Primary700Body2Link, Subtitle2Typography } from '../../themes/PortalStyling';
import MessageBox from '../../components/MessageBox';
import ConfigureSsoMessage from "../../components/ConfigureSsoMessage";
import ProfileBox from "../../components/ProfileBox";


const axiosInstance = axios.create();
const QUERY_PARAMETER_ERROR = "error";
const USERNAME_FIELD = "username";


export default function LoginForm() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [microsoftLoading, setMicrosoftLoading] = useState(false);
    const [emailSet, setEmailSet] = useState(false);
    const [accLocked, setAccLocked] = useState(false);
    const [passExpired, setPassExpired] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfigureSSOMessage, setShowConfigureSSOMessage] = useState(false);
    const [ssoConfigContactAddress, setSsoConfigContactAddress] = useState(null);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [mainButtonText, setMainButtonText] = useState("Continue");
    const [mainButtonLoading, setMainButtonLoading] = useState(false);
    const [requirementsMet, setRequirementsMet] = useState(0);
    const [newPassword, setNewPassword] = useState('');
    const [errorOnSignIn, setErrorOnSignIn] = useState(null);
    const [titleText, setTitleText] = useState("Log in");
    const [subtitleText, setSubtitleText] = useState("Enter your email address to continue.");
    const [userEmail, setUserEmail] = useState('');
    const [genericError, setGenericError] = useState('');
    const [genericErrorDetected, setGenericErrorDetected] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [identityProvider, setIdentityProvider] = React.useState(null);
    const ACC_LOCKED_ERR = 'account.locked';
    const PASS_EXPIRED_ERR = 'password.stale';
    const MS_ACC_LOCKED_ERR = 'account.locked';

    const LoginEmailValidationSchema = Yup.object().shape({
        username: Yup.string()
            .required("Required")
            .email("Invalid email"),
    })
    const LoginPasswordValidationSchema = Yup.object().shape({
        password: Yup.string()
            .required("Required")
    })
    const LoginSubmitValidationSchema = LoginEmailValidationSchema.concat(LoginPasswordValidationSchema);

    const {
        register,
        handleSubmit,
        formState: {errors},
        trigger,
        getValues
    } = useForm({
        resolver: yupResolver(LoginSubmitValidationSchema)
    });

    const passwordResetUrl = window.location.protocol + "//" + getNetworkPortalHost() + "/forgot-password";
    const loginUrl = window.location.protocol + "//" + getIdPortalHost() + "/login";

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setNewPassword(newPassword);

        const hasUpperCase = /[A-Z]/.test(newPassword);
        const hasLowerCase = /[a-z]/.test(newPassword);
        const hasNumber = /[0-9]/.test(newPassword);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);

        const metRequirements = [hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar].filter(Boolean).length;
        setRequirementsMet(metRequirements);
    };

    const updatePassword = async (username, password, newPassword) => {
        try {
            const response = await axiosInstance.post(
                `${window.location.protocol}//${getIdPortalHost()}/update-password`,
                { username, password, newPassword },
                {
                    withCredentials: true,
                    headers: {
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                    }
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    };

    const login = async (username, password) => {
        try {
            const response = await axiosInstance.post(
                `${window.location.protocol}//${getIdPortalHost()}/login`,
                { username, password },
                {
                    withCredentials: true,
                    headers: {
                        "Accept": "application/json",
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (!emailSet) {
                handleContinue();
            }
        }
    };

    const handleLoginResponse = (response) => {
        const { redirectUrl, qrCode, privateKey, requiresMfaSetup, requiresMfa } = response.data;
        if (rememberMe) {
            setStoredEmailAddress(getValues(USERNAME_FIELD));
        }
        if (requiresMfa) {
            navigate(redirectUrl, {
                state: { privateKey, qrCode, requiresMfaSetup }
            });
        } else if (redirectUrl) {
            window.location.href = redirectUrl;
        } else {
            redirectToDefaultLandingPage();
        }
    };

    const onSubmit = async (data) => {
        setGenericErrorDetected(false);
        setGenericError('');
        if (emailSet) { // to not display password missing error when pressing Enter key on email field
            setMainButtonLoading(true);
            const { username, password } = data;

            if (passExpired) {
                if (requirementsMet >= 3 && newPassword.length >= 10) {
                    setErrorOnSignIn(null);
                } else {
                    setErrorOnSignIn("Password does not meet the requirements.");
                    setMainButtonLoading(false);
                    return;
                }
                if (newPassword === password) {
                    setErrorOnSignIn("New password cannot be the same as the old password.");
                    setMainButtonLoading(false);
                    return;
                }

                try {
                    const updateResponse = await updatePassword(username, password, newPassword);
                    if (updateResponse.status === 200) {
                        const loginResponse = await login(username, newPassword);
                        handleLoginResponse(loginResponse);
                    } else {
                        handleToastError("Password update failed", { autoClose: false, closeOnClick: false });
                    }
                } catch (error) {
                    handleError(error);
                } finally {
                    setMainButtonLoading(false);
                }
            } else {
                try {
                    const loginResponse = await login(username, password);
                    handleLoginResponse(loginResponse);
                } catch (error) {
                    handleError(error);
                } finally {
                    setMainButtonLoading(false);
                }
            }
        }
    };

    const handleError = (errorResponse) => {
        let errorMessage = errorResponse.response?.data?.errorMessage;
        let errorCode = errorResponse.response?.data?.errorCode;
        if (errorCode === undefined) {
            errorCode = errorResponse.response?.data?.correlationId;
        }
        if (errorCode === ACC_LOCKED_ERR) {
            setAccLocked(true);
        } else if (errorCode === PASS_EXPIRED_ERR) {
            setPassExpired(true);
            setMainButtonText("Update and sign in");
        } else {
            setGenericErrorDetected(true);
            setGenericError(errorMessage);
        }
        setMainButtonLoading(false);
    };

    function handleContinue() {
        trigger(USERNAME_FIELD).then((result) => {
            setMainButtonLoading(true);
            if (result) {
                getOauth2ClientNameByEmail() //TODO - swap this to use the common utils version
                    .then(() => {
                        checkSSOEnforcement();
                    })
            }
        }).finally(() => {
            setMainButtonLoading(false)
        });
    }

    function returnToEmailEntry() {
        setEmailSet(false);
        setAccLocked(false);
        setPassExpired(false);
        setTitleText("Log in");
        setSubtitleText("Enter your email address to continue.");
        setMainButtonText("Continue");
        setUserEmail('');
        setGenericErrorDetected(false);
        document.cookie = `ELEMICA_EMAIL=''; max-age=0; samesite=strict; secure`;
    }

    function getOauth2ClientNameByEmail() {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post(
                    window.location.protocol + "//" + getIdPortalHost() + "/identity-provider-name-by-email",
                    { email: getValues(USERNAME_FIELD) },
                )
                .then(response => {
                    setIdentityProvider(response.data.oauth2ClientName);
                    response.data.contactAddress ? setSsoConfigContactAddress(response.data.contactAddress) : setSsoConfigContactAddress(null);
                    resolve(response);
                })
                .catch((errorResponse) => {
                    const errorMessage = errorResponse.response?.data?.errorMessage;
                    handleToastError(errorMessage, { autoClose: false, closeOnClick: false });
                    reject(errorResponse);
                })
        });
    }

    function logRedirectingToFederatedLogin(email, idp) {
        return new Promise((resolve, reject) => {
            const payload = { email: email, enabled: idp !== null, idp: idp }
            axiosInstance
                .post(
                    window.location.protocol + "//" + getIdPortalHost() + "/log-federated-login-attempt",
                    payload,
                )
                .then(response => {
                    resolve(response);
                })
                .catch((errorResponse) => {
                    // Don't do anything here and resolve as normal, don't want failed logging to block the user from logging in
                    resolve(errorResponse);
                })
        });
    }

    function checkSSOEnforcement() {
        axiosInstance
            .post(
                window.location.protocol + "//" + getIdPortalHost() + "/sso-enforcement",
                {email: getValues(USERNAME_FIELD)},
            )
            .then(response => {
                const ssoEnforced = response?.data?.ssoEnforced
                if (ssoEnforced) {
                    navigate("sso-enforced")
                } else {
                    setUserEmail(getValues(USERNAME_FIELD));
                    setEmailSet(true);
                    setMainButtonText("Log In");
                    setSubtitleText("Enter your password to log in.");
                }
            })
            .catch((errorResponse) => {
                const errorMessage = errorResponse.response?.data?.errorMessage;
                handleToastError(errorMessage, {autoClose: false, closeOnClick: false})
            })
    }

    function toggleRememberMe() {
        setRememberMe(!rememberMe);
    }

    function setStoredEmailAddress(email) {
        document.cookie = `ELEMICA_EMAIL=${email}; max-age=1209600; samesite=strict; secure`;
    }

    function getStoredEmailAddress() {
     const cookieValue = document.cookie
            .split('; ')
            .find((row) => row.startsWith('ELEMICA_EMAIL='))
            ?.split('=')[1];
        return cookieValue;
    }

    async function handleOauth2Signin(oauth2ClientName) {
        setMicrosoftLoading(true)
        if (rememberMe) {
            setStoredEmailAddress(getValues(USERNAME_FIELD));
        }
        await logRedirectingToFederatedLogin(getValues(USERNAME_FIELD), oauth2ClientName);
        window.location = getOauth2RedirectUrl(oauth2ClientName)
    }

    useEffect(() => {
        if (getStoredEmailAddress()) {
            setTitleText("Welcome back!");
            setRememberMe(true);
            handleContinue();
        }
        const error = searchParams.get(QUERY_PARAMETER_ERROR);
        searchParams.delete(QUERY_PARAMETER_ERROR);
        setSearchParams(searchParams);
        if (error) {
            const decodedError = atob(error);
            if (decodedError === MS_ACC_LOCKED_ERR) {
                setAccLocked(true);
            } else {
                handleToastError(decodedError, {autoClose: false, closeOnClick: false})
            }
        }
    }, [searchParams]);


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} noValidate sx={{mt: 1}}>
                {!showConfigureSSOMessage && <>
                    <GreyTextGrid container justifyContent="center" direction="column" alignItems="center">
                        <H4Typography sx={{ mb: 0 }}>{titleText}</H4Typography>
                        <Subtitle1Typography sx={{ mt: 0, mb: 1.5, mx: 6, textAlign: 'center' }}>{subtitleText}</Subtitle1Typography>
                    </GreyTextGrid>
                </>
                }

                <Grid container justifyContent="center">
                    {!showConfigureSSOMessage && <>
                        <Box sx={{ width: '80%' }}>
                            {!emailSet ? (
                                <TextField
                                    type="email"
                                    label="Email Address"
                                    required
                                    fullWidth
                                    autoFocus
                                    size="small"
                                    variant="outlined"
                                    InputProps={{
                                        style: {
                                            marginTop: '-1px',
                                        },
                                        readOnly: passExpired,
                                    }}
                                    {...register("username")}
                                    error={Boolean(errors.username)}
                                    helperText={errors.username?.message}
                                    onKeyDown={handleKeyDown}
                                    defaultValue={getStoredEmailAddress()}
                                />
                            ) : (
                                    <ProfileBox email={userEmail} />
                            )}
                            {!emailSet &&
                                <FormControlLabel
                                    control={<Checkbox checked={rememberMe} onChange={toggleRememberMe} name="rememberMe" color="primary" />}
                                    label={<Body2Label>Remember my email on this device</Body2Label>}
                                    sx={{ mt: 3, marginTop: '4px' }}
                                />
                            }
                            {emailSet && <>
                                <TextField // hidden field to help password managers detect the password field
                                    type="hidden"
                                    name="username"
                                    value={userEmail}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    {...register("username")}
                                    sx={{
                                        display: 'none',
                                    }}
                                />
                                <Grid item xs display="flex" justifyContent="center" sx={{ mb: 3 }}>
                                    <Primary700Body2Link onClick={returnToEmailEntry} sx={{ textAlign: 'center' }}>
                                        Sign in with another email address
                                    </Primary700Body2Link>
                                </Grid>
                                <TextField id="password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    label="Password"
                                    required
                                    fullWidth
                                    autoFocus
                                    size="small"
                                    sx={{ mt: 2, mb: 2 }}
                                    {...register("password")}
                                    error={Boolean(errors.password) || passExpired}
                                    helperText={errors.password?.message || (passExpired && "Your password has expired.")}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label={showPassword ? "Hide password" : "Show password"}
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        readOnly: passExpired,
                                    }}
                                    autoComplete="current-password"
                                    aria-describedby="password-helper-text"
                                />

                            </>
                            }

                            {accLocked &&
                                <MessageBox sx={{ mt: 3 }}
                                    message={
                                        <Body2Typography>Your account is locked. To unlock your account, please contact <a href="mailto:support@elemica.com">support@elemica.com</a>.</Body2Typography>
                                    }
                                    icon={<Error />}
                                    bgColor='#d11f2f17'
                                    iconColor='#D11F2F'
                                />
                            }
                            {genericErrorDetected &&
                                <MessageBox sx={{ mt: 2, mb: 1 }}
                                    message={
                                        <Body2Typography>{genericError}</Body2Typography>
                                    }
                                    icon={<Error />}
                                    bgColor='#d11f2f17'
                                    iconColor='#D11F2F'
                                />
                            }
                            {passExpired && <>
                                <TextField id="newPassword"
                                    type={showNewPassword ? 'text' : 'password'}
                                    label="New Password"
                                    required
                                    fullWidth
                                    autoFocus
                                    size="small"
                                    sx={{ mt: 2, mb: 1 }}
                                    {...register("newPassword")}
                                    error={Boolean(errors.password)}
                                    helperText={errors.password?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowNewPassword}
                                                >
                                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={handlePasswordChange}
                                />
                                <Box display="block" sx={{ backgroundColor: '#B0E0F4', color: 'black', padding: '8px', borderRadius: '4px', padding: 2 }}>
                                    <Grid container alignItems="flex-start">
                                        <Grid item xs={1}>
                                            <Avatar sx={{ bgcolor: '#007CBB', color: 'white', width: 24, height: 24, fontSize: '0.75rem' }}>i</Avatar>
                                        </Grid>
                                        <Grid item xs={11} sx={{ pl: 1 }}>
                                            <Body2Typography align="left">
                                                Password must be new, contain at least 10 characters and 3 of the following attributes:
                                            </Body2Typography>
                                            <Box>
                                                <Body2Typography align="left">- An uppercase letter</Body2Typography>
                                                <Body2Typography align="left">- A lowercase letter</Body2Typography>
                                                <Body2Typography align="left">- A number</Body2Typography>
                                                <Body2Typography align="left">- A special character</Body2Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                            }

                            <LoginButton
                                type={emailSet ? "submit" : "button"}
                                loadingPosition="end"
                                sx={{ mt: 1, mb: 2 }}
                                fullWidth
                                variant="contained"
                                onClick={emailSet ? () => {
                                } : () => {
                                    setMainButtonLoading()
                                    handleContinue();
                                }}
                                loading={mainButtonLoading}
                                disabled={mainButtonLoading}
                            >
                                {mainButtonText}
                            </LoginButton>
                            {errorOnSignIn && (
                                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                    {errorOnSignIn}
                                </Typography>
                            )}
                        </Box>
                    </>
                    }
                </Grid>

                {emailSet && !passExpired && identityProvider && !showConfigureSSOMessage && <>
                    <GreyTextGrid container justifyContent="center">
                        <Body2Typography>Or continue with:</Body2Typography>
                    </GreyTextGrid>

                    <Grid container justifyContent="center">
                        <Box sx={{width: '80%'}}>
                            <InvertedColoursButton
                                sx={{mt: 2, mb: 1}}
                                fullWidth
                                loading={microsoftLoading}
                                disabled={microsoftLoading}
                                loadingPosition="end"
                                variant="outlined"
                                onClick={
                                    identityProvider == 'NOT_CONFIGURED' ?
                                        () => {
                                            logRedirectingToFederatedLogin(getValues(USERNAME_FIELD), null);
                                            setShowConfigureSSOMessage(true);
                                        } :
                                        () => handleOauth2Signin(identityProvider)
                                }
                            >
                                Microsoft
                            </InvertedColoursButton>
                        </Box>
                    </Grid>
                    { !accLocked && !passExpired &&
                        <Grid item xs display="flex" justifyContent="center">
                            <Primary700Body2Link  sx={{ textAlign: 'center', mt: 2 }} href={passwordResetUrl}> Forgot password? </Primary700Body2Link>
                        </Grid>
                    }
                </>
                }

                {showConfigureSSOMessage &&
                    <ConfigureSsoMessage setShowConfigureSSOMessage={setShowConfigureSSOMessage} contactEmail={ssoConfigContactAddress} />
                }

            </form>
        </>
    )
}
