import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import axios from "axios";
import {getOauth2ClientNameByEmail, getOauth2RedirectUrl} from "../../utils/Common";
import {Box, Card, CardContent, Grid, Link} from '@mui/material';
import {Error} from '@mui/icons-material';
import SvgIcon from '@mui/material/SvgIcon';
import { BackgroundedCard, Body2Typography, BoxCentered, ErrorMessage, GreyTextGrid, H4Typography, InvertedColoursButton, LoginButton, CaptionLink, CaptionTypography } from "../../themes/PortalStyling";
import Logo from "../../assets/images/elemica_blue_gradient_large.svg";

import ProfileBox from "../../components/ProfileBox";
import ActivateAccountForm from '../../components/ActivateAccountForm';
import ConfigureSsoMessage from "../../components/ConfigureSsoMessage";
import MessageBox from '../../components/MessageBox';

const dockHeroImage = require('../../assets/images/hero-images/dock-hero.jpg');

export default function SignUp() {
    const { id } = useParams();
    const [email, setEmail] = useState('');
    const [errorGettingEmail, setErrorGettingEmail] = useState('');
    const [showActivationForm, setShowActivationForm] = useState(false);
    const [identityProvider, setIdentityProvider] = useState(null);
    const [ssoConfigContactAddress, setSsoConfigContactAddress] = useState(null);
    const [showConfigureSSOMessage, setShowConfigureSSOMessage] = useState(false);

    useEffect(() => {
        axios.get(`/signup/user/${id}`)
            .then((response) => {
                setEmail(response?.data?.email);
                return response?.data?.email;
            })
            .catch((error) => {
                console.error('Error fetching email:', error);
                setErrorGettingEmail(error.response?.data);
            })
    }, [id]);

    useEffect(() => {
        if(email) {
            getOauth2ClientNameByEmail(axios, email)
                .then((response) => {
                    setIdentityProvider(response.data.oauth2ClientName);
                    setSsoConfigContactAddress(response.data.contactAddress ?? null);
                })
                .catch((error) => {
                    console.error('Error fetching identity provider:', error);
                })
        }
    }, [email])

    return (
        <BackgroundedCard  heroImage={dockHeroImage}>
            <CardContent sx={{ backgroundColor: 'white',
                            maxWidth: '420px',
                            margin: 'auto', padding: '8'}}>
                <BoxCentered sx={{ display: 'flex', flexDirection: 'column', px: 5 }}>
                    <Grid container display="flex" justifyContent="center" alignItems="center" sx={{mt: 5, mb: 3}}>
                        <Link to="https://elemica.com/">
                            <img src={Logo} alt="Logo" width="80px" height="20px"/>
                        </Link>
                    </Grid>
                    <GreyTextGrid container justifyContent="center" direction="column" alignItems="center">
                        <H4Typography variant="h5" component="h2" gutterBottom>
                            Activate Account
                        </H4Typography>
                    </GreyTextGrid>

                    {!errorGettingEmail ?
                        (<>
                            {!showConfigureSSOMessage ?
                                (<>
                                    <ProfileBox email={email} />
                                    {!showActivationForm ?
                                        (<>
                                            <Box mt={2} mb={3}>
                                                <LoginButton
                                                    type="button"
                                                    loadingPosition="end"
                                                    sx={{ mt: 1, mb: 2 }}
                                                    fullWidth
                                                    variant="contained"
                                                    onClick={() => setShowActivationForm(true)}
                                                endIcon={<></>}>
                                                    Create Password
                                                </LoginButton>
                                                <GreyTextGrid container justifyContent="center">
                                                    <Body2Typography>Or continue with:</Body2Typography>
                                                </GreyTextGrid>
                                                <InvertedColoursButton
                                                    type="button"
                                                    loadingPosition="end"
                                                    sx={{ mt: 1, mb: 2 }}
                                                    fullWidth
                                                    variant="outlined"
                                                    disabled={!(identityProvider || ssoConfigContactAddress)}
                                                    onClick={
                                                        identityProvider == 'NOT_CONFIGURED' ?
                                                            () => {
                                                                setShowConfigureSSOMessage(true);
                                                            } :
                                                            () => window.location = getOauth2RedirectUrl(identityProvider)
                                                    }endIcon={<></>}
                                                >
                                                    Microsoft
                                                </InvertedColoursButton>
                                            </Box>
                                        </>) :
                                        (<ActivateAccountForm id={id} />)}
                                </>) :
                                (<ConfigureSsoMessage setShowConfigureSSOMessage={setShowConfigureSSOMessage} contactEmail={ssoConfigContactAddress} />)}
                        </>) :
                        (<Card sx={{ mt: 2, mb: 3 }}>
                            <MessageBox message={errorGettingEmail.errorMessage}
                                icon={<Error />}
                                bgColor='#d11f2f17'
                                iconColor='#D11F2F'
                            />
                        </Card>)}

                    <Box display="block" sx={{ ml: 4, mr: 4 }}>
                        <GreyTextGrid>
                            <CaptionTypography>
                                By activating your account, have read and acknowledge our <CaptionLink href="https://elemica.com/legal/privacy/">Privacy Policy</CaptionLink>.
                            </CaptionTypography>
                        </GreyTextGrid>
                    </Box>
                </BoxCentered>
            </CardContent>
        </BackgroundedCard>
    );
}
