import { styled } from '@mui/system';
import { Card, CardContent, Link as MuiLink, Typography, Grid, createTheme, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";


export const noAsteriskTheme = createTheme({
    components: {
        MuiInputLabel: {
            styleOverrides: {
                asterisk: {
                    display: 'none',
                },
            },
        },
    },
});

export const CaptionLink = styled(MuiLink)`
    font-size: 12px;
    letter-spacing: 0.4px;
`;

export const Body2Link = styled(MuiLink)`
    font-size: 14px;
    letter-spacing: 0.25px;
`;

export const Primary700Body2Link = styled(MuiLink)`
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #007CBB;
    cursor: pointer;
`;

export const BoxCentered = styled(Box)`
    display: block;
    text-align: center;
`;

export const LoginBox = styled(CardContent)`
    max-width: 390px;
    width: 100%;
    border-radius: 4px;
    background-color: white;
    flex-direction: column;
    display: flex;
    form {
        label {
            display: inline-block;
            font-size: 0.79rem;
            color: #818181;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
`;

export const LoginButton = styled(LoadingButton)`
    background: linear-gradient(90deg, #2dc2f1 0, #1e98d4 100%);
    letter-spacing: 1px;
`;

export const H1Typography = styled(Typography)`
    font-size: 96px;
    letter-spacing: -1.5px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
`;

export const H2Typography = styled(Typography)`
    font-size: 60px;
    letter-spacing: -0.5px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
`;

export const H3Typography = styled(Typography)`
    font-size: 48px;
    letter-spacing: 0px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
`;

export const H4Typography = styled(Typography)`
    font-size: 34px;
    letter-spacing: 0.25px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
`;

export const H5Typography = styled(Typography)`
    font-size: 24px;
    letter-spacing: 0px;
    font-family: 'Open Sans', sans-serif;
`;

export const H6Typography = styled(Typography)`
    font-size: 20px;
    letter-spacing: 0.25px;
    font-family: 'Open Sans', sans-serif;
`;

export const Body1Typography = styled(Typography)`
    font-size: 16px;
    letter-spacing: 0.5px;
    font-family: 'Open Sans', sans-serif;
`;

export const Body2Typography = styled(Typography)`
    font-size: 14px;
    letter-spacing: 0.25px;
    font-family: 'Open Sans', sans-serif;
`;

export const Subtitle1Typography = styled(Typography)`
    font-size: 16px;
    letter-spacing: 0.15px;
    font-family: 'Open Sans', sans-serif;
`;

export const Subtitle2Typography = styled(Typography)`
    font-size: 14px;
    letter-spacing: 0.1px;
    font-family: 'Open Sans', sans-serif;
`;

export const CaptionTypography = styled(Typography)`
    font-size: 12px;
    letter-spacing: 0.4px;
    font-family: 'Open Sans', sans-serif;
`;

export const OverlineTypography = styled(Typography)`
    font-size: 12px;
    letter-spacing: 2px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
`;


export const GreyTextGrid = styled(Grid)`
    color: #808080;
`;

export const InvertedColoursButton = styled(LoadingButton)`
    border: 1px solid lightgray;
    color: #1e98d4;
    font-weight: bold;
`;

export const HorizontalLine = styled(Box)({
    width: '100%',
    borderTop: '1px solid lightgray',
    marginTop: '32px',
    marginBottom: '32px',
});

export const BackgroundedCard = styled(Card)`
    display: flex;
    background-image: url(${props => props.heroImage});
    height: 100vh;
    background-size: cover;
    display: grid;
    place-items: center;
    overflow-y: auto;
    max-height: 100vh;
`;

export const ErrorMessage = styled(CardContent)`
    background-color: #d11f2f17;
`;

export const Body2Label = styled('span')(({ theme }) => ({
    textTransform: 'none',
    fontSize: '14px',
    letterSpacing: '0.25px',
    fontFamily: "'Open Sans', sans-serif",
    position: 'relative',
    top: '2px',
}));