import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

export default function ({ message, bgColor, icon, iconColor, ...props }) {
    return (
        <Card {...props}>
            <CardContent sx={{ backgroundColor: bgColor, padding: '8px', display: 'flex', alignItems: 'center' }}>
                <Grid container direction="row" wrap="nowrap" spacing={1} alignItems="center" sx={{ display: 'flex' }}>
                <Grid item sx={{ padding: '0px', marginTop: '15px' }}>
                        <SvgIcon sx={{ color: iconColor }}>
                            {icon}
                        </SvgIcon>
                    </Grid>
                    <Grid item sx={{ padding: '0px', marginTop: '15px' }}>
                        {message}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};